@import '@cognitiv/cassiopeia-ui/dist/luna';

.container {
  display: flex;
  gap: 8px;
  padding: 0 16px;
  width: 100%;
}

.options {
  display: flex;
  gap: 8px;
}

.line {
  border-right: 1px solid $border-separator;
  margin: 16px 32px 16px 16px;
}

.sticky {
  position: sticky;
  top: 0;
  background-color: $background-secondary;
  z-index: 10;
}

.title {
  display: flex;
  align-items: center;
  height: 32px;
  font-size: 13px;
  font-weight: 500;
}

.option {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  cursor: pointer;
  gap: 8px;
  padding: 8px;
  min-height: 32px;
  border-radius: 4px;

  &.active {
    background-color: $background-primary;
  }

  .icon {
    height: 16px;
    width: 16px;
    color: $icon-sidebaractive-normal;
  }

  .description {
    width: fit-content;
    white-space: nowrap;
    align-self: center;
    font-size: 11px;
    color: $text-tertiary;
  }
}

.scrollable {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 600px;
  margin: 6px 6px 20px;
  padding: 0 2px;
}

.resetOffsets {
  margin: 0;
  padding: 0;
}

.empty {
  padding: 16px;
  font-style: italic;
  font-size: 12px;
  color: $text-tertiary;
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  top: 16px;
  right: 16px;
}
