@import '@cognitiv/cassiopeia-ui/dist/luna';

.option {
  display: flex;
  justify-content: flex-start;
  font-weight: 400;
  gap: 8px;
  border-radius: 4px;
  padding: 6px 8px;
  background: $background-field-normal;
  cursor: pointer;
  color: $text-primary;

  &:hover {
    background: $background-field-hover;
    outline: 1px solid transparent;
  }

  &:focus {
    background: $background-field-focus;
    outline: 1px solid $blue;
  }

  &:active {
    background: $background-field-pressed;
    outline: 1px solid transparent;
  }

  p {
    flex-grow: 1;
    font-size: inherit;
    font-family: inherit;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.option.selected {
  color: $text-primary;
  background: $background-field-selected;
}

.option.parent {
  color: $text-primary;
  background: $background-primary;
}
