@import '@cognitiv/cassiopeia-ui/dist/luna';

.menu {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: -358px;
  width: calc(100% - 50px);
  min-height: 308px;
  max-height: 1350px;
  left: 50px;
  background: $background-secondary;
  z-index: 5;
  box-sizing: border-box;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;

  &.is_open {
    top: 50px;
  }
}

.columns {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;

  > div {
    border-left: 1px solid $border-separator;
    border-radius: 1px;
  }

  > div:last-child {
    border-right: none;
  }
}

.max {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
