.page {
  width: 100%;
  height: 100%;
  overflow: auto;


}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
  gap: 16px;
  // min-height: 100%;
  // height: fit-content;
  height: 100%;
}