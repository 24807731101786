@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import '@cognitiv/cassiopeia-ui/dist/luna';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  font-size: 13px;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: $background-primary;
  color: $text-primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none;
}