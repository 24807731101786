@import '@cognitiv/cassiopeia-ui/dist/luna';

.loading {
  z-index: 9999;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.loading.dark {
  background-color: transparent;
}

.loading.light {
  display: none;
  background-color: transparent;
}

.loading.transparent {
  background-color: transparent;
}

.spinner {
  animation: spin 1.75s linear infinite;
  height: 45px;
  width: 45px;
}

.small {
  height: 25px;
  width: 25px;
}

.large {
  height: 32px;
  width: 32px;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.blur {
  z-index: 9998;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(14, 16, 17, 0.5);
  backdrop-filter: blur(2px);
}
