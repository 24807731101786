@import '@cognitiv/cassiopeia-ui/dist/luna';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 225px;
  padding: 20px;
  gap: 8px;
}

.grow {
  flex-grow: 1;
}

.option {
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 8px;
  color: $text-primary;
  cursor: pointer;
  border-radius: 8px;
  height: 36px;
  align-items: center;

  &:hover {
    background: $background-subtle-hover;
  }

  &:active {
    background: $background-subtle-pressed;
  }
}

.option.selected {
  color: $text-invert-normal;
  background-color: $background-primary-normal;
}

.separator {
  border-top: 1px solid $border-separator;
  margin: 8px 0;
}

.back {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 0 8px 10px;
  color: $text-plain-normal;
  cursor: pointer;
}

.back:hover {
  color: $text-plain-hover;
}

.back:active {
  color: $text-plain-pressed;
}
