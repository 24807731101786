@import '@cognitiv/cassiopeia-ui/dist/luna';

.column {
  display: flex;
  flex-direction: column;
  color: $text-primary;
  margin: 20px 0;
  padding: 0 20px;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  padding: 0 0 20px 0;

  img {
    width: 80px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  height: 18px;
  gap: 16px;
  margin-bottom: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1px;
}
